import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import { Container, Row, Col } from 'react-bootstrap'
import CategoryNav from '../components/categories/nav'
import AddThis from '../components/misc/addthis'
import LayoutContext from '../components/layout/context'

class SocialPage extends React.Component {
  static contextType = LayoutContext

  componentWillMount() {
    this.context.set({
      meta: get(this, 'props.data.contentfulMetaTags'),
    })
  }

  componentDidMount() {
    if (typeof document !== 'undefined') {
      const script = document.createElement('script')
      script.src =
        'https://cdn.curator.io/published/032819a8-63fc-4d7c-846e-f5d3e873f37e.js'
      script.async = true
      this.instance.appendChild(script)
    }
  }

  render() {
    return (
      <>
        <div className="bg-light">
          <Container className="py-5">
            <Row>
              <Col md="3" className="pb-3 pb-md-0">
                <h2 className="h2 hu">#OneYard</h2>
                <nav className="social-icon-nav">
                  <a
                    href="https://www.instagram.com/1Yard.Co/"
                    target="_blank"
                    className="social-nav-link"
                    title="Follow One Yard on Instagram"
                  >
                    <i className="icon-instagram"></i>
                  </a>
                  <a
                    href="https://www.facebook.com/1Yard.Co"
                    target="_blank"
                    className="social-nav-link"
                    title="Follow One Yard on Facebook"
                  >
                    <i className="icon-facebook"></i>
                  </a>
                  <a
                    href="https://twitter.com/1yardco"
                    target="_blank"
                    className="social-nav-link"
                    title="Follow One Yard on Twitter"
                  >
                    <i className="icon-twitter"></i>
                  </a>
                </nav>
              </Col>
              <Col>
                <div id="curator-feed-default-feed-layout">
                  <a
                    href="https://curator.io"
                    target="_blank"
                    className="crt-logo crt-tag"
                  >
                    Powered by Curator.io
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div ref={(el) => (this.instance = el)}></div>

        <CategoryNav />
      </>
    )
  }
}
export default SocialPage

export const pageQuery = graphql`
  query SocialIndexQuery {
    contentfulMetaTags(pagePath: { eq: "/social" }) {
      title
      image {
        gatsbyImageData(width: 1200, height: 630)
      }
      url
      description {
        description
      }
    }
  }
`
